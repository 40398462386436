<template>
  <svg
    v-if="available"
    xmlns="http://www.w3.org/2000/svg"
    width="15.146"
    height="11.45"
    viewBox="0 0 15.146 11.45"
  >
    <path
      id="Path_628"
      data-name="Path 628"
      d="M16992.643,1186.15l2.143,3.825,8.855-7.067"
      transform="translate(-16990.602 -1180.799)"
      fill="none"
      stroke="#00CCCC"
      stroke-linecap="round"
      stroke-width="3"
      class="svg-icon-stroke"
    />
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="12.4"
    height="12.4"
    viewBox="0 0 12.4 12.4"
  >
    <g
      id="Group_1364"
      data-name="Group 1364"
      transform="translate(2.121 2.121)"
    >
      <path
        id="Path_60"
        data-name="Path 60"
        d="M16838.5,1226.869l8.156,8.157"
        transform="translate(-16838.5 -1226.869)"
        fill="none"
        stroke="#f0142f"
        stroke-linecap="round"
        stroke-width="3"
        class="svg-icon-stroke"
      />
      <path
        id="Path_61"
        data-name="Path 61"
        d="M16849.146,1226.869l-8.158,8.157"
        transform="translate(-16840.988 -1226.869)"
        fill="none"
        stroke="#f0142f"
        stroke-linecap="round"
        stroke-width="3"
        class="svg-icon-stroke"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    available: {
      type: Boolean,
      default: false,
    },
  },
};
</script>